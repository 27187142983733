import { isMobile } from "mobile-device-detect";

export default {
  chart1: {
    type: "PieChart",
    pagination: {
      value: 1,
      total: 0
    },
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      //pieSliceText: 'label',
      chartArea: {
        width: isMobile ? "100%" : "90%",
        height: isMobile ? "70%" : "90%"
      },
      legend: {
        position: isMobile ? "top" : "right",
        alignment: "center",
        maxLines: 10
      },
      vAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0,
      totalSoldSelection: 0
    }
  },
  chart2: {
    type: "PieChart",
    pagination: {
      value: 1,
      total: 0
    },
    isDefaultMode: true,
    isColumnType: false,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      is3D: true,
      chartArea: {
        width: isMobile ? "100%" : "90%",
        height: isMobile ? "70%" : "90%"
      },
      legend: {
        position: isMobile ? "top" : "right",
        alignment: "center",
        maxLines: 10
      },
      vAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    },
    totals: {
      totalSold: 0,
      totalSoldSelection: 0
    }
  },
  chart3: {
    pagination: {
      value: 1,
      total: 0
    },
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      chartArea: {
        width: isMobile ? "50%" : "80%",
        height: "80%"
      },
      legend: {
        position: "none"
      },
      vAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    }
  },
  chart4: {
    pagination: {
      value: 1,
      total: 0
    },
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      chartArea: {
        width: isMobile ? "50%" : "80%",
        height: "80%"
      },
      legend: {
        position: "right"
      },
      vAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    }
  },
  chart5: {
    pagination: {
      value: 1,
      total: 0
    },
    isDefaultMode: true,
    rawData: [],
    emptyStates: [],
    loading: false,
    subtitle: "",
    chartData: null,
    chartOptions: {
      height: 500,
      chartArea: {
        width: isMobile ? "50%" : "80%",
        height: "80%"
      },
      legend: {
        position: "right"
      },
      vAxis: {
        textStyle: {
          fontSize: 12
        }
      }
    }
  }
};

export default {
  chart1Table: {
    name: "custom_table_accounts_products_sales_chart1",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Product", "$ Sold", "Bottles", "Amount of alcohol"],
      totalColumns: [
        {
          name: "$ Sold",
          alias: "Sold",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "$ Sold",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        }
      ]
    }
  },
  chart2Table: {
    name: "custom_table_accounts_products_sales_chart2",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Product", "$ Sold", "Bottles", "Amount of alcohol"],
      totalColumns: [
        {
          name: "$ Sold",
          alias: "Sold",
          style: {
            style: "currency",
            currency: "USD"
          }
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "$ Sold",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        }
      ]
    }
  },
  chart3Table: {
    name: "custom_table_accounts_products_sales_chart3",
    isLoading: false,
    dataSet: [],
    options: {
      columns: [
        "Product",
        "Rank 1",
        "Rank 2",
        "$ Sold 1",
        "$ Sold 2",
        "Difference",
        "% Change"
      ],
      cellClasses: {
        "Rank 1": [
          {
            class: "bg-success",
            condition: row => row["Rank 1"] < row["Rank 2"]
          },
          {
            class: "bg-danger",
            condition: row => row["Rank 1"] > row["Rank 2"]
          }
        ]
      },
      totalColumns: [
        {
          name: "$ Sold 1",
          alias: "Sold 1",
          style: {
            style: "currency",
            currency: "USD"
          }
        },
        {
          name: "$ Sold 2",
          alias: "Sold 2",
          style: {
            style: "currency",
            currency: "USD"
          }
        },
        {
          name: "Difference",
          alias: "Difference",
          style: {
            style: "currency",
            currency: "USD"
          },
          formula:
            "${self.totals.find(t => t.alias === 'Difference').value} (${(self.totals.find(t => t.alias === 'Difference').value*100/self.totals.find(t => t.alias === 'Sold 1').value).toFixed(2)} %)"
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "$ Sold 1",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        },
        {
          name: "$ Sold 2",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        },
        {
          name: "Difference",
          style: {
            style: "currency",
            currency: "USD"
          },
          type: "number"
        },
        {
          name: "% Change",
          style: {
            style: "decimal"
          },
          type: "number"
        }
      ]
    }
  },
  chart3TableBottles: {
    name: "custom_table_accounts_products_sales_chart3",
    isLoading: false,
    dataSet: [],
    options: {
      columns: [
        "Product",
        "Rank 1",
        "Rank 2",
        "Bottles 1",
        "Bottles 2",
        "Difference",
        "% Change"
      ],
      cellClasses: {
        "Rank 1": [
          {
            class: "bg-success",
            condition: row => row["Rank 1"] < row["Rank 2"]
          },
          {
            class: "bg-danger",
            condition: row => row["Rank 1"] > row["Rank 2"]
          }
        ]
      },
      totalColumns: [
        {
          name: "Bottles 1",
          alias: "Bottles 1",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Bottles 2",
          alias: "Bottles 2",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Difference",
          alias: "Difference",
          style: {
            style: "decimal"
          },
          formula:
            "${self.totals.find(t => t.alias === 'Difference').value} (${(self.totals.find(t => t.alias === 'Difference').value*100/self.totals.find(t => t.alias === 'Bottles 1').value).toFixed(2)} %)"
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "Bottles 1",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Bottles 2",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Difference",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "% Change",
          style: {
            style: "decimal"
          },
          type: "number"
        }
      ]
    }
  },
  chart3TableAlcAmount: {
    name: "custom_table_accounts_products_sales_chart3",
    isLoading: false,
    dataSet: [],
    options: {
      columns: [
        "Product",
        "Rank 1",
        "Rank 2",
        "Amount 1",
        "Amount 2",
        "Difference",
        "% Change"
      ],
      cellClasses: {
        "Rank 1": [
          {
            class: "bg-success",
            condition: row => row["Rank 1"] < row["Rank 2"]
          },
          {
            class: "bg-danger",
            condition: row => row["Rank 1"] > row["Rank 2"]
          }
        ]
      },
      totalColumns: [
        {
          name: "Amount 1",
          alias: "Amount 1",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Amount 2",
          alias: "Amount 2",
          style: {
            style: "decimal"
          }
        },
        {
          name: "Difference",
          alias: "Difference",
          style: {
            style: "decimal"
          },
          formula:
            "${self.totals.find(t => t.alias === 'Difference').value} (${(self.totals.find(t => t.alias === 'Difference').value*100/self.totals.find(t => t.alias === 'Amount 1').value).toFixed(2)} %)"
        }
      ],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: [
        {
          name: "Amount 1",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Amount 2",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "Difference",
          style: {
            style: "decimal"
          },
          type: "number"
        },
        {
          name: "% Change",
          style: {
            style: "decimal"
          },
          type: "number"
        }
      ]
    }
  },
  chart4Table: {
    name: "custom_table_accounts_products_sales_chart4",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Product", "Invoices", "Accounts"],
      totalColumns: [],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: []
    }
  },
  chart5Table: {
    name: "custom_table_accounts_products_sales_chart5",
    isLoading: false,
    dataSet: [],
    options: {
      columns: ["Product", "Invoices", "Accounts"],
      totalColumns: [],
      footerHeadings: false,
      perPage: 10,
      disablePerPageDropdown: false,
      showCustomActions: false,
      formatColumns: []
    }
  }
};
